import React, { useState, useContext, useEffect, useCallback } from "react";
import { useAxios } from "../../utils/axios";
import { CartContext } from "../../contexts/CartContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { UserContext } from "../../contexts/UserContext";
import { useRouter } from "next/router";
import CustomPopup from "../../components/CustomPopup";
import { Snackbar } from "../../components/ProductsGrid/Snackbar";

const DynamicQuantityButton = ({ product, uiMode,isCheckout=false, isCart,isRelated="",fetchCartProducts={} }) => {
  const axiosInstance = useAxios();
    const theme = useContext(ThemeContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProduct, setSnackbarProduct] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupConfig, setPopupConfig] = useState({});
  const [quantity, setQuantity] = useState("0");
  const [previousQuantity, setPreviousQuantity] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const { cart, fetchCart, fetchCartCount } = useContext(CartContext);
  const { token, userDetails } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (cart && Array.isArray(cart)) {
      const productIdToCompare =
        uiMode == 1 ? product.product_id1 || product.mProductId : product.product_id;
      const cartItem = cart.find(
        (item) => item.product_id1 === productIdToCompare
      );
      if (cartItem) {
        const qtyString = String(cartItem.qty);
        setQuantity(qtyString);
        setPreviousQuantity(qtyString);
      }
    }
  }, [cart, product.product_id, product.product_id1, uiMode]);

  // Add keydown event listener for left/right arrow keys
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        window.location.reload(); // Reload the page
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //test


  const handleQuantityUpdate = async (newQuantity) => {

    if (theme.siteStatus == 0) {
      openPopup({
        type: 'info',
        title: 'Preview Mode',
        subText: 'Actions like "Add to Cart" are disabled during preview.',
        onConfirm: closePopup,
        onClose: closePopup,
        showConfirmButton: false,
        showCancelButton: false,
        autoClose: 3,
      });
      return; 
    }
  

    if (!userDetails ) { 
      const url = window.location.href;
      document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;
      router.push("/auth/SignIn");
      return;
    }

    if (isLoading) return;

    setIsLoading(true);
    const payload = {
      mProductId: product?.product_id1,
      product_id: uiMode == 1 ? product.product_id1 || product.mProductId : product.product_id,
      quantity: newQuantity, // Pass 0 if quantity is 0
    };

    try {
      const response = await axiosInstance.post(
        "user/setaddtocartQuantity",
        payload
      );

      if (response.data?.status === 1) {
        setPreviousQuantity(String(newQuantity)); // Update previousQuantity on success
        setQuantity(String(newQuantity));
        fetchCartCount();
        setSnackbarProduct(product);
        setSnackbarOpen(true);
        if(isCheckout){
          fetchCartProducts()
        }
      } else {
        setQuantity(previousQuantity); // Revert to previousQuantity on failure
        // message Updated
        const errorMessage = response.data?.message || "something went wrong";
        const title =
          errorMessage === "We apologize, but the quantity you've requested has reached its maximum limit for this product"
            ? `Maximum Limit - ${response?.data?.qty} qty`
            : "Error";

          const type = errorMessage === "We apologize, but the quantity you've requested has reached its maximum limit for this product" ? "info":"error"
        openPopup({
          type: type,
          title: title,
          subText: errorMessage,
          onConfirm: closePopup,
          onClose: closePopup,
          showConfirmButton: false,
          showCancelButton: false,
          cancelLabel: "close",
          autoClose: 3,
        });

      }
      fetchCart();

    } catch (error) {
      setQuantity(previousQuantity); // Revert to previousQuantity on error

    } finally {
      setIsLoading(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedQuantityUpdate = useCallback(
    debounce((newQuantity) => {
      handleQuantityUpdate(newQuantity);
    }, 1000),
    [previousQuantity]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
  
    // Allow only numeric values and empty input
    if (/^\d*$/.test(value)) {
      setQuantity(value);
  
      // Call debouncedQuantityUpdate only if the value is not empty
      if (value.trim() !== "") {
        debouncedQuantityUpdate(parseInt(value, 10));
      }
    }
  };
  const handleInputBlur = () => {
    // If the input is empty on blur, reset it to the previous quantity
    if (quantity.trim() === "") {
      setQuantity(previousQuantity);
    }
  };  

  const openPopup = (config) => {
    setPopupConfig(config);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  const buttonClass =
    uiMode == 1
      ? "text-primary text-sm px-2 py-1 rounded-md transform transition-all hover:scale-105"
      : "text-white text-lg px-4 py-2 rounded-full transform transition-all hover:scale-105";

  const containerClass =
    uiMode == 1
      ? `flex items-center shadow-sm rounded-md px-2 py-1 gap-2 border border-primary ${
        isLoading ? "opacity-50 pointer-events-none" : ""
      } sm:px-1 sm:py-0.5 sm:gap-1`// Compact container with loading feedback
      : `flex  items-center bg-primary rounded-full px-4 py-2 shadow-md gap-4 ${
          isLoading ? "opacity-50 pointer-events-none" : ""
        }`; // Default container with loading feedback

  return (
    <div className="flex items-center gap-2">
      {quantity == "0" ? (
        <button
          className={`bg-gray-800 text-white shadow-lg transform transition-all hover:scale-105 ${
            uiMode == 1
              ? "text-sm px-3 py-2 rounded-md"
              : "text-lg px-6 py-3 sm:py-4 rounded-full"
          }`}
          onClick={() => handleQuantityUpdate(1)}
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <i className="fas fa-spinner fa-spin"></i> Loading...
            </span>
          ) : (
            <span
              className={`flex items-center ${
                uiMode == 1 ? "gap-1 text-sm" : "gap-2 text-lg"
              }`}
            >
              <i
                className={`fas fa-plus-circle ${
                  uiMode == 1 ? "text-xs" : "text-base"
                }`}
              ></i>
              {uiMode == 2 ? "Add to Cart":"Add"}
            </span>
          )}
        </button>
      ) : (
        <div className={containerClass}>
          <button
            className={buttonClass}
            onClick={() => handleQuantityUpdate(Math.max(parseInt(quantity, 10) - 1, 0))}
            disabled={isLoading}
          >
            {isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <i className="fas fa-minus"></i>
            )}
          </button>
          <div className="flex items-center justify-center">
            <input
              type="text"
              value={quantity}
              onChange={handleInputChange}
              onBlur={handleInputBlur} // Reset to default value on outside click
              className={`text-center w-5 lg:w-6 bg-transparent focus:outline-none appearance-none text-base ${uiMode == 1 ? 'text-primary':"text-white"}`}
              disabled={isLoading}
              style={{
                border: "none",
                boxShadow: "none",
                outline: "none",
                padding: "0px",
              }}
            />
          </div>
          <button
            className={buttonClass}
            onClick={() => handleQuantityUpdate(parseInt(quantity, 10) + 1)}
            disabled={isLoading}
          >
            {isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <i className="fas fa-plus"></i>
            )}
          </button>
        </div>
      )}

      <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={closePopup}
        onClose={closePopup}
        autoClose={popupConfig.autoClose}
        confirmLabel="Ok"
        cancelLabel="Close"
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        showCloseIcon={true}
        redirectOnClose={popupConfig.redirectOnClose}
      />
      {(quantity ) && ( // Only render Snackbar if quantity > 0
        <Snackbar
          product={snackbarProduct}
          isOpen={snackbarOpen}
          setIsOpen={setSnackbarOpen}
          quantity={quantity}
          relatedProducts={isRelated == 1 ? "true": "false"}
        />
      )}
    </div>
  );
};

export default DynamicQuantityButton;
