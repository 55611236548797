import React, { useEffect } from "react";

const SnowfallEffect = () => {
  useEffect(() => {
    const endDate = new Date("2025-01-01");

    if (new Date() > endDate) {
      // Stop the snowball effect
      return;
    }
    const snowflakeContainer = document.querySelector(".snowflake-container");

    const createSnowflakes = () => {
      for (let i = 0; i < 100; i++) {
        const snowflake = document.createElement("div");
        snowflake.className = "snowflake";
        snowflake.style.left = `${Math.random() * 100}vw`;
        snowflake.style.animationDuration = `${Math.random() * 3 + 3}s`; // Falling speed
        snowflake.style.fontSize = `${Math.random() * 10 + 10}px`; // Snowflake size
        snowflake.innerText = "❄";
        snowflakeContainer.appendChild(snowflake);

        // Remove snowflake after animation ends
        snowflake.addEventListener("animationend", () => {
          snowflake.remove();
        });
      }
    };

    createSnowflakes();
  }, []);

  return <div className="snowflake-container"></div>;
};

export default SnowfallEffect;
