import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ThemeContext } from "../../contexts/ThemeContext";
import { RedisContext } from "../../contexts/RedisContext";
import { BUILDER_URL } from "../../utils/config";
import Image from "next/image";
import parse from 'html-react-parser'; // Import only parse, no need for domToReact here

import root from 'react-shadow'; // React shadow root for shadow DOM

const BASE_URL = BUILDER_URL;

const CustomSection = ({ widgetId }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useContext(ThemeContext);
  // const {redisWidgetOrderData} = useContext(RedisContext);
  useEffect(() => {
    if (widgetId) {
      axios
        .get(`${BASE_URL}/cms-custom/${widgetId}`, {
          headers: { Authorization: `Bearer ${theme.token}` },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setData(response.data[0]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setData(null);
          setIsLoading(false);
         
        });
    }
  }, [widgetId, theme]);

  // useEffect(() => {
  //   // Find matching widget data based on widgetId
  //   if (redisWidgetOrderData && widgetId) {
  //     const widgetData = redisWidgetOrderData.find(
  //       (widget) => widget.widget_order_id === widgetId
  //     );
  //     if (widgetData) {
  //       setData(widgetData.cmsCustom
  //         ? widgetData.cmsCustom
  //         [0] : null);
  //     }
  //     else{
  //       setData(null);
  //     }
  //   }
  // }, [widgetId, redisWidgetOrderData]);

console.log("custom datas",data);

  if (!data) return null;


  // Function to replace img tags with Next.js Image components
  const replaceImgWithNextImage = (domNode) => {
    if (domNode.name === 'img') {
      const { src, alt, width, height } = domNode.attribs;

      // Default width and height if not provided
      const imgWidth = width ? parseInt(width) : 500;
      const imgHeight = height ? parseInt(height) : 500;

      // Return Next.js Image component
      return (
        <img
          key={src}
          src={src}
          alt={alt || 'Image'}
          width={imgWidth}
          height={imgHeight}
          layout="responsive"
          objectFit="cover"
        />
      );
    }
  };

  // Parse HTML and replace img tags with Next.js Image components
  const options = {
    replace: replaceImgWithNextImage,
  };

  // Determine if the content is HTML or CMS custom editor
  const htmlContent = data.is_html_tag === 1 ? data.html_editor : data.cms_custom_editor;

  // Parsed content with img tag replacement
// Validation to ensure `htmlContent` is a string before parsing - updated 13/09/2024
const parsedContent = htmlContent && typeof htmlContent === 'string'
? parse(htmlContent, options)
: null;

  return (
    <>
      <span className="text-xl lg:text-2xl pb-1 lg:pb-4 text-center px-3 lg:px-0 font-semibold text-primary flex justify-center">
        {data.widget_title}
      </span>
      
      <div className="block xl:container w-full mx-auto px-4 py-6 bg-white rounded-md">
        {
          data.is_html_tag === 1 ? (
            <root.div>
              <link
                rel="stylesheet"
                href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
              />
              <div style={{ maxWidth: "100%" }}>
                {parsedContent} {/* Render parsed content in Shadow DOM */}
              </div>
            </root.div>
          ) : (
            <div
              className="prose prose-sm sm:prose lg:prose-lg mx-auto sun-editor-editable flex justify-center items-center"
              style={{ maxWidth: "100%" }}
              dangerouslySetInnerHTML={{ __html: data.cms_custom_editor }}
            ></div>
          )
        }
      </div>
    </>
  );
};

export default CustomSection;
